var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"申请切换状态"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"原状态"}},[_vm._v(_vm._s(this.record.effective? "有效" : "失效"))]),_c('a-form-item',{attrs:{"label":"目标状态"}},[_vm._v(_vm._s(!this.record.effective? "有效" : "失效"))]),_c('a-form-item',{attrs:{"label":"变更原因"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['reason', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入变更原因' },
              { max: 200, message: '最多200个字符' } ]
          }]),expression:"['reason', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入变更原因' },\n              { max: 200, message: '最多200个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"第一审核人"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['first_checker_id', {
            initialValue: _vm.financeDefaultId,
            rules: [{ required:true,type: 'number',message: '请选择财务' }],
          }]),expression:"['first_checker_id', {\n            initialValue: financeDefaultId,\n            rules: [{ required:true,type: 'number',message: '请选择财务' }],\n          }]"}]},_vm._l((_vm.financeOptions),function(financier){return _c('a-select-option',{key:financier.id,attrs:{"value":financier.id,"disabled":!financier.effective}},[_vm._v(" "+_vm._s(financier.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"第二审核人"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['second_checker_id', {
            initialValue: _vm.administrativeDefaultId,
            rules: [{ required:true,type: 'number',message: '请选择行政' }]
          }]),expression:"['second_checker_id', {\n            initialValue: administrativeDefaultId,\n            rules: [{ required:true,type: 'number',message: '请选择行政' }]\n          }]"}]},_vm._l((_vm.administrativeOptions),function(administrator){return _c('a-select-option',{key:administrator.id,attrs:{"value":administrator.id,"disabled":!administrator.effective}},[_vm._v(" "+_vm._s(administrator.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"第三审核人"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['third_checker_id', {
            initialValue: _vm.managerDefaultId,
            rules: [{ required:true,type: 'number',message: '请选择总经理' }]
          }]),expression:"['third_checker_id', {\n            initialValue: managerDefaultId,\n            rules: [{ required:true,type: 'number',message: '请选择总经理' }]\n          }]"}]},_vm._l((_vm.managerOptions),function(manager){return _c('a-select-option',{key:manager.id,attrs:{"value":manager.id,"disabled":!manager.effective}},[_vm._v(" "+_vm._s(manager.name)+" ")])}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }